import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbAuthService, NbResetPasswordComponent, NB_AUTH_OPTIONS } from '@nebular/auth';
import { NbComponentStatus } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../services/authentication.service';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'ngx-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent extends NbResetPasswordComponent{
  submitted = false;
  date: Date = new Date();
  backendUrl: string = environment.backendUrl;
  resetPassForm?: FormGroup;
  loading = false;
  returnUrl: string;
  error = '';
  userSubscription: Subscription;
  user: any;
  status: NbComponentStatus = 'info';

  constructor(
    protected service: NbAuthService,
    protected cd: ChangeDetectorRef,
    @Inject(NB_AUTH_OPTIONS) options = {},
    protected router: Router,
    private authenticationService: AuthenticationService,
    private generalService: GeneralService,
  ) { 
    super(service, options, cd, router);
  }

  ngOnInit() {
    this.resetPassForm = new FormGroup({
      password: new FormControl('', Validators.required),
      rePass: new FormControl('', Validators.required),
    });

  }

  onSubmit() {
    this.submitted = true;
    // console.log('this.f');

    this.authenticationService.resetPassword(this.f.password.value)
      .pipe(first())
      .subscribe(data => {
        // console.log(data);
          this.loading = false;
          // this.router.navigate(['/']);
          this.generalService.showToast(this.status, "Mot de passe", 'Modification effectué avec succés! Re-connectez vous.');
          this.authenticationService.logout();

        },
        error => {
          console.log('Error after  requesting password');
          console.log(error.message);
          this.errors = [];
          this.error = "Une erreur est survenue ! Veuillez réessayer plus tard!";
          this.errors.push(this.error);
          this.loading = false;
          this.submitted = false;
          return;
        }
      );

  }

  get f() { return this.resetPassForm.controls; }

}
