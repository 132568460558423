import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbComponentStatus } from '@nebular/theme';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { GeneralService } from './general.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  appName = environment.appName;
  public currentUser: Observable<User>;
  private currentUserSubject: BehaviorSubject<User>;
  public path: Observable<any>;
  private pathSubject: BehaviorSubject<any>;
  public rolesPermissions: Observable<any>;
  public expiryTime: Observable<any>;
  private expiryTimeSubject: BehaviorSubject<any>;
  backendUrl: string = environment.backendUrl;
  appCode = environment.appCode;
  
  public roles: Observable<any>;
  public rolesSubject: BehaviorSubject<any>;
  public permissions: Observable<any>;
  public permissionsSubject: BehaviorSubject<any>;
  // 
  public userObj: any[];
  public userConnected: boolean = false;
  public userConnectedStr: string = "false";
  public tokenExpireAt: number =  0;
  userSubject = new Subject<any>();

  status: NbComponentStatus = 'warning';

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { 
    
    this.setDefaultData();
    this.tokenExpireAt = this.getLocalStorageItem('tokenExpireAt');

    this.rolesSubject = new BehaviorSubject<any>(this.getLocalStorageItem('roles', 'object'));
    this.roles = this.rolesSubject.asObservable();
    
    this.permissionsSubject = new BehaviorSubject<any>(this.getLocalStorageItem('permissions', 'object'));
    this.permissions = this.permissionsSubject.asObservable();
    
    const now = new Date();
    if(now.getTime() > this.tokenExpireAt) {
      this.logout();
    } else {
      const remaining = this.tokenExpireAt - now.getTime();
      // console.log('Remaining :: ' + remaining);
      // console.log('Exp AT :: ' + this.tokenExpireAt);
      // console.log('NOw at :: ' + this.tokenExpireAt);
      if (remaining < 2 * 1000 && this.currentUserSubject.value) {
        this.increaseExpiryDate(remaining);
      }
    }

  }

  public get currentUxerValue() {
    return this.userObj;
  }

  public get isConnected() {
    return this.userConnected;
  }

  public get tokenEgzpireAt() {
    return this.tokenExpireAt;
  }

  resetPassword(newPassword: string) {
    const params = {
      'new_password': newPassword
    };

    return this.http.post<any>(this.backendUrl + `/api/admin/auth/reset-password`, params).pipe(map(result => {
      // console.log('result of reset', result)
      return result;
    },
      (error) => {
        return null;
      }
    ));
  }

  requestPassword(email: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'skip': 'true',
      }),
    };

    const params = {
      'email': email
    };

    return this.http.post<any>(this.backendUrl + `/api/admin/auth/request-password`, params, httpOptions).pipe(map(result => {
      
      console.log('result of rrwqqq', result)

      return result;
    },
      (error) => {
        // console.log('Error during admin registration');
        return null;
      }
    ));

  }

  register(controls) {
    // console.log('registering');
    // console.log('controls', controls);

    return this.http.post<any>(this.backendUrl + `/api/admin/auth/register`, controls )
      .pipe(map(result => {
        return result;
      },
        (error) => {
          return null;
        }
      ));


  }

  login(email: string, password: string) {
    // console.log('logging on api');
    const httpOptions = {
      headers: new HttpHeaders({
        'skip': 'true',
      }),
    };
    this.setLocalStorageItem('token', null);
    return this.http.post<any>(this.backendUrl + `/api/admin/auth/login`, { email, password }, httpOptions )
      .pipe(map(result => {
        const user = result.user
        // console.log('resulr of login', result?.user) 

        if(user?.active ){
          this.setLocalStorageItem('token', result?.authorisation?.token);
          localStorage.setItem('userObj', JSON.stringify(user));
          localStorage.setItem('userConnected', "true");
          this.userSubject.next(user);

          this.setDefaultData();

          // Set expiry time
          const date = new Date();
          const minutes = 5;
          const expiryTime = new Date(date.getTime() + minutes * 60000).getTime();
          const tokenExpireAt = new Date(date.getTime() + minutes * 60000 ).getTime()
          this.setLocalStorageItem('expiryTime', expiryTime);
          this.setLocalStorageItem('tokenExpireAt', tokenExpireAt);

          this.getRolesPermissions(result?.authorisation?.token)
          .subscribe(
            data2 => {
              this.router.navigate(['/'])
              .then(() => {
                window.location.reload();
              });
            }
          );

          return result;
        } else {
          return null;
        }
      },
        (error) => {
          console.log('credentials not matching');
          return null;
        }
      ));
  }
  
  refresh() {
    console.log('refresh');
    this.http.post<any>(this.backendUrl + `/api/admin/auth/refresh`, {}).subscribe(result => {
      console.log('refresh', result);
      this.setLocalStorageItem('token', result?.authorisation?.token);
      const sessionTime = new Date().getTime();
      this.setLocalStorageItem('sessionTime', sessionTime);
      return result;
    },
      (error) => {
        this.logout(this.router.url);
        const sessionTime = new Date().getTime();
        this.setLocalStorageItem('sessionTime', sessionTime);
        return null;
      }
    );
  }

  getRolesPermissions(token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + token,
        'skip': 'true',
      }),
    };

    return this.http.post<any>(this.backendUrl + `/api/admin/auth/me/roles-permissions`, {}, httpOptions)
      .pipe(
        map(result => {
          // console.log('roles', result?.roles);
          // console.log('rPermissions', result?.permissions);
          
          this.setLocalStorageItem('roles', JSON.stringify(result?.roles));
          this.rolesSubject.next(result?.roles);
          
          this.setLocalStorageItem('permissions', JSON.stringify(result?.permissions));
          this.permissionsSubject.next(result?.permissions);

          return {
            'roles': result?.roles,
            'permissions': result?.permissions
          };
        }
      )
    );
  }


  setDefaultData() {
    this.userObj = JSON.parse(localStorage.getItem('userObj'));
      this.userConnectedStr = localStorage.getItem('userConnected')
    if(this.userConnectedStr == "true") {
      this.userConnected = true;
    }
  }
  
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  public get expiryTimeValue(): User {
    return this.expiryTimeSubject.value;
  }

  logout(returnUrl = '') {
    // remove user from local0 storage to log user out

    const params = {};
    // console.log('logout params', params);
    this.http.post<any>(this.backendUrl + `/api/admin/auth/logout`, params).subscribe((result) => {
      this.handleLogout();
      // this.generalService.showToast(this.status, "Authentification", "Déconnexion effectué avec succès");
      this.router.navigate(['/auth/login'], {queryParams: {returnUrl}});
    },
      (error) => {
        this.handleLogout();
        this.router.navigate(['/auth/login'], {queryParams: {returnUrl}});
      }
    );
  }

  handleLogout() {
    this.removeSession();
    this.currentUserSubject?.next(null);
    this.userSubject?.next(null);
    this.rolesSubject?.next(null);
    this.permissionsSubject?.next(null);
  }

  removeSession() {
    localStorage.removeItem('expiryTime');
    localStorage.removeItem('userConnected');
    localStorage.removeItem('userConnectedStr');
    localStorage.removeItem('tokenExpireAt');
    localStorage.removeItem('userObj');
    localStorage.removeItem('sessionTime');
    localStorage.removeItem('path');
    localStorage.removeItem('token');
    localStorage.removeItem('currentCustomer');
    localStorage.removeItem('menuItems');
    localStorage.removeItem('settings');
    localStorage.removeItem('settingData');
    localStorage.removeItem('roles');
    localStorage.removeItem('permissions');

    this.userObj = [];
    this.userConnected = false;

    // console.log('userCon ::: ', this.getLocalStorageItem('userConnected'));
    // console.log('userConStr ::: ', this.getLocalStorageItem('userConnectedStr'));
  }

  setLocalStorageItem(name, enteredValue) {
    const value = {};
    value[this.appName] = {};
    value[this.appName][this.appCode] = enteredValue;
    try {
      localStorage.setItem(name, JSON.stringify(value));
    } catch (e) { }
  }
  
  getLocalStorageItem(name: any, type = 'string'): any {
    let temp: any = {};
    try {
      temp = JSON.parse(String(localStorage.getItem(name)));
      // console.log('temp ' + name, temp);

    } catch (e) { }
    let value: any;
    if (temp && temp[this.appName] && this.appCode) {
      value = temp[this.appName][this.appCode];
    }
    if (type == 'object') {
      try {
        value = JSON.parse(value);
      } catch (e) { }
    }

    // console.log('type', typeof value)
    // console.log('value getLocalStorageItem', value);
    return value;
  }

  getSessionStartTime() {
    let val;
    const sessionStartTime = this.getLocalStorageItem('sessionStartTime');
    try {
      const now = new Date().getTime();
      const remaining = now - sessionStartTime;
      val = new Date((remaining / 1000) * 1000).toISOString().substr(11, 8);
    } catch (e) {

    }
    return val;
  }
  getLoginRemainingTime() {
    const currentExpiryTime = this.getLocalStorageItem('expiryTime');
    const now = new Date().getTime();
    const remaining = currentExpiryTime - now;
    if (remaining < 40 * 1000 && this.currentUserSubject.value) {
      this.increaseExpiryDate(remaining);
    }
    let val;
    if (currentExpiryTime && this.currentUserSubject.value) {
      // console.log('this.currentCustomerSubject.value', currentExpiryTime);
      if (remaining) {
        val = new Date((remaining / 1000) * 1000).toISOString().substr(11, 8);
      } else {
        this.logout();
      }
    }
    return val;
  }
  increaseExpiryDate(remaining) {
    const date = new Date();
    const minutes = 20;
    const expiryTime = new Date(date.getTime() + minutes * 60000 + remaining).getTime();
    const tokenExpireAt = new Date(date.getTime() + minutes * 60000 + remaining).getTime()
    this.setLocalStorageItem('expiryTime', expiryTime);
    this.setLocalStorageItem('tokenExpireAt', expiryTime);
    // console.log('expiryTime', expiryTime);
  }
  getSessionTime() {
    const sessionTime = this.getLocalStorageItem('sessionTime');
    const now = new Date().getTime();
    const remaining = now - sessionTime;
    if (remaining > 45 * 60 * 1000 && this.currentUserSubject.value) {
      this.refresh();
    }
    const val = new Date((remaining / 1000) * 1000).toISOString().substr(11, 8);
    return val;
  }



}
