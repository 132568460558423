import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NbAuthService, NbRequestPasswordComponent, NB_AUTH_OPTIONS } from '@nebular/auth';
import { NbComponentStatus } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../services/authentication.service';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'ngx-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss']
})
export class RequestPasswordComponent extends NbRequestPasswordComponent {
  submitted = false;
  date: Date = new Date();
  backendUrl: string = environment.backendUrl;
  requestPassForm?: FormGroup;
  loading = false;
  returnUrl: string;
  error = '';
  userSubscription: Subscription;
  user: any;
  status: NbComponentStatus = 'info';

  constructor(
    protected service: NbAuthService,
    protected cd: ChangeDetectorRef,
    @Inject(NB_AUTH_OPTIONS) options = {},
    protected router: Router,
    private authenticationService: AuthenticationService,
    private generalService: GeneralService,
  ) { 
    super(service, options, cd, router);
  }

  ngOnInit(): void {
    this.requestPassForm = new FormGroup({
      email: new FormControl('', Validators.required),
    });

  }

  onSubmit() {
    this.submitted = true;
    // console.log('this.f');
    // console.log(this.f);
    if (this.requestPassForm.invalid) {
      this.submitted = false;
      this.error = 'Informations invalides. ';
      this.errors = [];
      this.errors.push(this.error);

      return;
    }
    this.errors = [];
    this.loading = true;

    // console.log('Yooo');

    this.authenticationService.requestPassword(this.f.email.value)
      .pipe(first())
      .subscribe(data => {
          this.loading = false;
          // this.router.navigate(['/']);
          console.log('data from xyz', data);
          // show toast and nav to login
          this.generalService.showToast(this.status, "Récupération de mot de passe", 'Vérifiez votre boîte mail, nous avons envoyé un nouveau mot de passe !');
          this.router.navigate(['/auth/login']);

        },
        error => {
          console.log('Error after  requesting password');
          console.log(error.message);
          this.errors = [];
          this.error = "Une erreur est survenue ! Vérifiez votre adresse mail";
          this.errors.push(this.error);
          this.loading = false;
          this.submitted = false;
          return;
        }
      );
  }

  get f() { return this.requestPassForm.controls; }

  

}
