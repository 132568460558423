<h1 id="title" class="title text-primary"> Xonam </h1>
<p class="sub-title">Bonjour ! Connectez-vous à l'aide de votre adresse email.</p>

<nb-alert *ngIf="errors.length" status="danger" >
  <!-- <p class="alert-title"><b>Oh snap!</b></p> -->
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message text-white text-bold"><strong>{{ error }}</strong></li>
  </ul>
</nb-alert>

<form (ngSubmit)="onSubmit()" [formGroup]="loginForm" aria-labelledby="title">

  <div class="form-control-group">
    <label class="label" for="input-email">Email:</label>
    <input nbInput
           fullWidth
           formControlName="email"
           id="input-email"
           pattern=".+@.+\..+"
           placeholder="Adresse Email"
           fieldSize="large"
           autofocus 
           >

    <ng-container *ngIf="submitted && f.email.errors">
      <p class="caption status-danger" *ngIf="f.email.errors?.required">
        Email obligatoire!
      </p>
      <p class="caption status-danger" *ngIf="f.email.errors?.pattern">
        Veuillez fournir une vraie addresse!
      </p>
    </ng-container>
  </div>

  <div class="form-control-group">
    <span class="label-with-link">
      <label class="label" for="input-password">Mot de passe:</label>
      <a class="forgot-password caption-2 " routerLink="/auth/request-password"> Mot de passe oublié ?</a>
    </span>
    <input nbInput
           fullWidth
           type="password"
           formControlName="password"
           id="input-password"
           placeholder="Mot de passe"
           fieldSize="large"
           >
    <ng-container *ngIf="">
      <p class="caption status-danger" *ngIf="password.errors?.required">
        Mot de passe obligatoire!
      </p>
      <!-- <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
        Password should contain
        from {{ getConfigValue('forms.validation.password.minLength') }}
        to {{ getConfigValue('forms.validation.password.maxLength') }}
        characters
      </p> -->
    </ng-container>
  </div>

  <!-- <div class="form-control-group accept-group">
    <nb-checkbox name="rememberMe" >Se rappeler de moi !</nb-checkbox>
  </div> -->

  <button nbButton
          fullWidth
          status="primary"
          size="large"
          [disabled]="submitted"
          [class.btn-pulse]="submitted"
          [nbSpinner]="loading" 
          nbSpinnerStatus="primary"
          >
    Connexion
  </button>
</form>

<section class="another-action" aria-label="Register">
  Pas de compte ? <a class="text-link text-info">Veuillez contacter un administrateur.  </a>
</section>