<div class="row">
  <div class="col-md-12 col-lg-4 col-xxxl-4">
    <nb-card>
      <nb-card-header>Common Datepicker</nb-card-header>
      <nb-card-body>
        <input nbInput
               placeholder="Form Picker"
               [nbDatepicker]="formpicker">
        <nb-datepicker #formpicker></nb-datepicker>
      </nb-card-body>
    </nb-card>
  </div>

  <div class="col-md-12 col-lg-4 col-xxxl-4">
    <nb-card>
      <nb-card-header>Datepicker With Range</nb-card-header>
      <nb-card-body>
        <input nbInput placeholder="Range Picker" [nbDatepicker]="rangepicker">
        <nb-rangepicker #rangepicker></nb-rangepicker>
      </nb-card-body>
    </nb-card>
  </div>
  <div class="col-md-12 col-lg-4 col-xxxl-4">
    <nb-card>
      <nb-card-header>Datepicker With Disabled Min Max Values</nb-card-header>
      <nb-card-body>
        <input nbInput placeholder="Min Max Picker" [nbDatepicker]="picker">
        <nb-datepicker #picker [min]="min" [max]="max"></nb-datepicker>
      </nb-card-body>
    </nb-card>
  </div>
</div>
