import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { GeneralService } from '../services/general.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private generalService: GeneralService,
  ) { } 


  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = this.authenticationService.currentUxerValue;
    let is = Object.keys(currentUser ?? {}).length ;
    if(is > 0) {
      // console.log('Current user conn in guard ::: ', currentUser);
      // console.log('Current user conn in guard ::: ', is);
      // if(currentUser['active'] == 0) {
      //   this.generalService.showToast('danger', 'Compte désactivé', 'Votre compte a été désactivé. Veuillez contacté un administrateur pour en savoir plus.');
      //   this.authenticationService.logout();
      //   return false;
      // } else {
      // }
      return true;

    }
    
    this.router.navigate(['/auth'], { queryParams: { returnUrl: encodeURI(state.url) } });
    return false;
  }
  
}
