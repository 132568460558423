<h1 id="title" class="title">Récupération de mot de passe</h1>
<p class="sub-title">Veuillez fournir votre adresse mail. Nous vous enverrons de nouveaux identifiants</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oups!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Hooray!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
  </ul>
</nb-alert>
  

<form (ngSubmit)="onSubmit()" [formGroup]="requestPassForm" aria-labelledby="title">



  <div class="form-control-group">
    <label class="label" for="input-email">Adresse email:</label>
    <input nbInput
        id="email"
        name="email"
        formControlName="email"
        pattern=".+@.+..+"
        placeholder="Email"
        fullWidth
        autocomplete="off"
        fieldSize="large"
        [status]="f.email.dirty ? (f.email.invalid  ? 'danger' : 'success') : 'basic'"
        [attr.aria-invalid]="f.email.invalid && f.email.touched ? true : null">
    <ng-container *ngIf="f.email.invalid && f.email.touched">
        <p class="caption status-danger" *ngIf="f.email.errors?.required">
            Champ obligatoire
        </p>
        <p class="caption status-danger" *ngIf="email.errors?.pattern">
            Veuillez fournir un email valide
        </p>
    </ng-container>
  </div>

  <button nbButton
          fullWidth
          status="primary"
          size="large"
          [disabled]="submitted || !requestPassForm.valid"
          [class.btn-pulse]="submitted">
    Envoyer la requête
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="login">Se connecter</a></p>
  <!-- <p><a routerLink="../register" class="text-link">Register</a></p> -->
</section>
