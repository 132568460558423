import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  token = null;

  constructor(
  ) {}

  intercept(
    request: HttpRequest<any>, 
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    try {

      const temp = JSON.parse(localStorage.getItem('token'));
      if(temp){
        const tempToken = temp[environment.appName][environment.appCode];
        // console.log('token intercepted :::', tempToken);
        if(tempToken) {
          this.token = tempToken;
        }
      }
    } catch (e) {
      console.log('Error intercepted ::', e)
    }

    const body = request.body;
    if (request.headers.get('skip') == 'true') {
      // console.log('skip this :: ' + request.url);
      request = request.clone({
        headers: request.headers.delete('skip'),
        body,
      });
      return next.handle(request);
    } else {
      request = request.clone(
        {
          headers: this.setHeaders(request.headers),
          body,
        },
      ); 

      // console.log('requ header::', request.headers);
    }

    return next.handle(request);
  }

  setHeaders(headers: any): any {
    headers = headers.set('Accept', 'application/json');
    // console.log('this.token :::', this.token);
    if (this.token) {
      headers = headers.set('Authorization', `Bearer ${this.token}`);
      return headers;
    }
  }
}
