import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  // toastr
  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;


  constructor(
    private toastrService: NbToastrService,
    private router: Router,
    private authenticationService: AuthenticationService,
  ) { }

  showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? `${title}` : '';

    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }

  redirectTo(path = '') {
    if(path.length > 0) {
      this.router.navigate([path]);
    }
  }

  validateItemWithPerms(permissions=[]) {
    let show = false
    let allPermissions: any[];
    this.authenticationService.permissions.subscribe(x => allPermissions = x);


    // validate
    permissions.forEach((perm: any)=> {
      // console.log('one P', perm);

      // if(allPermissions) {
      const availablePerm = allPermissions?.find(x => x.name == perm);
      // console.log('availll', availablePerm);
      if(availablePerm) {
        show = true
      }
      // }
    });

    // console.log('show', show);

    return show;
  }

  validateItemWithRoles(roles=[]) {
    let show = false
    let allRoles: any[];
    this.authenticationService.roles.subscribe(x => allRoles = x);

    roles.forEach((role: any)=> {
      const availableRole = allRoles?.find(x => x == role);
      if(availableRole) {
        show = true
      }
    });

    return show;
  }

  addPrefix(object: any, prefix: any) {
    const data: any = {};
    Object.keys(object).forEach(key => data[prefix + key] = object[key]);
    return data;
  }

}
