<div class="row">
  <div class="col-md-12">
    <nb-card class="inline-form-card">
      <nb-card-header>Inline form</nb-card-header>
      <nb-card-body>
        <form class="form-inline">
          <input type="text" nbInput fullWidth placeholder="Jane Doe">
          <input type="text" nbInput fullWidth placeholder="Email">
          <nb-checkbox>Remember me</nb-checkbox>
          <button type="submit" nbButton status="primary">Submit</button>
        </form>
      </nb-card-body>
    </nb-card>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <nb-card>
      <nb-card-header>Using the Grid</nb-card-header>
      <nb-card-body>
        <form>
          <div class="form-group row">
            <label for="inputEmail1" class="label col-sm-3 col-form-label">Email</label>
            <div class="col-sm-9">
              <input type="email" nbInput fullWidth id="inputEmail1" placeholder="Email">
            </div>
          </div>
          <div class="form-group row">
            <label for="inputPassword2" class="label col-sm-3 col-form-label">Password</label>
            <div class="col-sm-9">
              <input type="password" nbInput fullWidth id="inputPassword2" placeholder="Password">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 label">Radios</label>
            <div class="col-sm-9">
              <nb-radio-group>
                <nb-radio>Option 1</nb-radio>
                <nb-radio>Option 2</nb-radio>
                <nb-radio disabled>Disabled Option</nb-radio>
              </nb-radio-group>
            </div>
          </div>
          <div class="form-group row">
            <div class="offset-sm-3 col-sm-9">
              <button type="submit" nbButton status="primary">Sign in</button>
            </div>
          </div>
        </form>
      </nb-card-body>
    </nb-card>

    <nb-card>
      <nb-card-header>Form without labels</nb-card-header>
      <nb-card-body>
        <form>
          <div class="form-group">
            <input type="text" nbInput fullWidth placeholder="Recipients">
          </div>
          <div class="form-group">
            <input type="text" nbInput fullWidth placeholder="Subject">
          </div>
          <div class="form-group">
            <textarea nbInput placeholder="Message"></textarea>
          </div>
          <button type="submit" nbButton status="success">Send</button>
        </form>
      </nb-card-body>
    </nb-card>
  </div>

  <div class="col-md-6">
    <nb-card>
      <nb-card-header>Basic form</nb-card-header>
      <nb-card-body>
        <form>
          <div class="form-group">
            <label for="exampleInputEmail1" class="label">Email address</label>
            <input type="email" nbInput fullWidth id="exampleInputEmail1" placeholder="Email">
          </div>
          <div class="form-group">
            <label for="exampleInputPassword1" class="label">Password</label>
            <input type="password" nbInput fullWidth id="exampleInputPassword1" placeholder="Password">
          </div>
          <div class="form-group">
            <nb-checkbox>Check me out</nb-checkbox>
          </div>
          <button type="submit" nbButton status="danger">Submit</button>
        </form>
      </nb-card-body>
    </nb-card>

    <nb-card>
      <nb-card-header>Block form</nb-card-header>
      <nb-card-body>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="inputFirstName" class="label">First Name</label>
              <input type="text" nbInput fullWidth id="inputFirstName" placeholder="First Name">
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="inputLastName" class="label">Last Name</label>
              <input type="text" nbInput fullWidth id="inputLastName" placeholder="Last Name">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <label for="inputEmail" class="label">Email</label>
              <input type="email" nbInput fullWidth id="inputEmail" placeholder="Email">
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label for="inputWebsite" class="label">Website</label>
              <input type="text" nbInput fullWidth id="inputWebsite" placeholder="Website">
            </div>
          </div>
        </div>
        <button type="submit" nbButton>Submit</button>
      </nb-card-body>
    </nb-card>
  </div>
</div>

<div class="row">
  <div class="col-md-6">
    <nb-card>
      <nb-card-header>Horizontal form</nb-card-header>
      <nb-card-body>
        <form class="form-horizontal">
          <div class="form-group row">
            <label for="inputEmail3" class="label col-sm-3 form-control-label">Email</label>
            <div class="col-sm-9">
              <input type="email" nbInput fullWidth id="inputEmail3" placeholder="Email">
            </div>
          </div>
          <div class="form-group row">
            <label for="inputPassword3" class="label col-sm-3 form-control-label">Password</label>
            <div class="col-sm-9">
              <input type="password" nbInput fullWidth id="inputPassword3" placeholder="Password">
            </div>
          </div>
          <div class="form-group row">
            <div class="offset-sm-3 col-sm-9">
              <div class="checkbox">
                <nb-checkbox>Remember me</nb-checkbox>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="offset-sm-3 col-sm-9">
              <button type="submit" nbButton status="warning">Sign in</button>
            </div>
          </div>
        </form>
      </nb-card-body>
    </nb-card>
  </div>
</div>
