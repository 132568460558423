import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Copyright &copy; <b><a href="https://cdfdh.com" target="_blank">CDFDH</a></b> 2022 
      | POWERED BY <a href="https://alabani.online" target="_blank"><b>CHAKIR TECHNOLOGIES</b></a> 
      | <a href="https://wa.me/0022893071593" target="_blank" class="ion ion-social-whatsapp"></a>
      | <a href="https://www.linkedin.com/in/ch%C3%A2kirou-alabani-63636917b" target="_blank" class="ion ion-social-linkedin"></a>
    </span>
    <!-- <div class="socials">
      <a href="#" target="_blank" class="ion ion-social-github"></a>
      <a href="#" target="_blank" class="ion ion-social-facebook"></a>
      <a href="#" target="_blank" class="ion ion-social-twitter"></a>
      <a href="#" target="_blank" class="ion ion-social-linkedin"></a>
    </div> -->
  `,
})
export class FooterComponent {
}
