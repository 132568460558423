<h1 id="title" class="title">Changer de mot de passe</h1>
<p class="sub-title">Déinissez un nouveau mot de passe</p>

<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Oh snap!</b></p>
  <ul class="alert-message-list">
    <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
  </ul>
</nb-alert>



<form (ngSubmit)="onSubmit()" [formGroup]="resetPassForm" aria-labelledby="title">

    <div class="form-control-group">
        <label class="label" for="password">Nouveau mot de passe :</label>
        <input nbInput
            type="password"
            id="password"
            name="password"
            formControlName="password"
            autocomplete="off"
            placeholder="Mot de passe"
            fullWidth
            fieldSize="large"
            [status]="f.password.dirty ? (f.password.invalid  ? 'danger' : 'success') : 'basic'"
            [attr.aria-invalid]="f.password.invalid && f.password.touched ? true : null">
        <ng-container *ngIf="f.password.invalid && f.password.touched">
            <p class="caption status-danger" *ngIf="f.password.errors?.required">
                Champ obligatoire
            </p>
        </ng-container>
    </div>

    <div class="form-control-group">
        <label class="label" for="input-re-password">Confirmez mot de passe:</label>
        <input nbInput
            type="password"
            id="re-password"
            name="rePass"
            formControlName="rePass"
            placeholder="Confirmez"
            fullWidth
            fieldSize="large"
            [status]="f.rePass.dirty ? (f.rePass.invalid || f.password.value != f.rePass.value  ? 'danger' : 'success') : 'basic'"
            [attr.aria-invalid]="f.rePass.invalid && f.rePass.touched ? true : null">
        <ng-container *ngIf="f.rePass.invalid && f.rePass.touched">
            <p class="caption status-danger" *ngIf="f.rePass.errors?.required">
                Confirmez le mot de passe
            </p>
            <p class="caption status-danger" *ngIf="f.password.value != f.rePass.value && !f.rePass.errors?.required">
                Les mots de passe ne correspondent pas
            </p>
        </ng-container>
    </div>

  <button nbButton
          status="primary"
          fullWidth
          size="large"
          [disabled]="submitted || !resetPassForm.valid"
          [class.btn-pulse]="submitted">
    Changer le mot de passe
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="/">Retourner à l'acceuil</a></p>
  <!-- <p><a class="text-link" routerLink="../register">Register</a></p> -->
</section>
