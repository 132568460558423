<div class="row">
  <div class="col-lg-12">
    <nb-card>
      <nb-card-header>Hero Buttons</nb-card-header>
      <nb-card-body>
        <div class="buttons-row">
          <button *ngFor="let status of statuses" nbButton [status]="status" hero>
            {{ status }}
          </button>
          <button disabled nbButton hero>
            disabled
          </button>
        </div>
      </nb-card-body>
    </nb-card>

    <nb-card>
      <nb-card-header>Default Buttons</nb-card-header>
      <nb-card-body>
        <div class="buttons-row">
          <button *ngFor="let status of statuses" nbButton [status]="status">
            {{ status }}
          </button>
          <button disabled nbButton>
            disabled
          </button>
        </div>
      </nb-card-body>
    </nb-card>

    <nb-card>
      <nb-card-header>Outline Buttons</nb-card-header>
      <nb-card-body>
        <div class="buttons-row">
          <button *ngFor="let status of statuses" nbButton [status]="status" outline>
            {{ status }}
          </button>
          <button disabled nbButton outline>
            disabled
          </button>
        </div>
      </nb-card-body>
    </nb-card>

    <nb-card>
      <nb-card-header>Ghost Buttons</nb-card-header>
      <nb-card-body>
        <div class="buttons-row">
          <button *ngFor="let status of statuses" nbButton [status]="status" ghost>
            {{ status }}
          </button>
          <button disabled nbButton outline>
            disabled
          </button>
        </div>
      </nb-card-body>
    </nb-card>
  </div>

  <div class="col-md-12 col-lg-12 col-xxxl-6">
    <nb-card>
      <nb-card-header>Button shapes</nb-card-header>
      <nb-card-body>
        <div class="buttons-row">
          <button *ngFor="let shape of shapes" nbButton [shape]="shape">
            {{ shape }}
          </button>
        </div>
      </nb-card-body>
    </nb-card>

    <nb-card>
      <nb-card-header>Button sizes</nb-card-header>
      <nb-card-body>
        <div class="buttons-row">
          <button *ngFor="let size of sizes" nbButton [size]="size">
            {{ size }}
          </button>
        </div>
      </nb-card-body>
    </nb-card>
  </div>

  <div class="col-md-12 col-lg-12 col-xxxl-6">
    <nb-card class="actions-card">
      <nb-card-header>Action Groups</nb-card-header>
      <nb-card-body>
        <nb-actions size="medium">
          <nb-action icon="menu-outline"></nb-action>
          <nb-action icon="search-outline"></nb-action>
          <nb-action icon="email-outline"></nb-action>
          <nb-action disabled icon="bell-outline"></nb-action><nb-action>
          <nb-user name="Han Solo"></nb-user>
        </nb-action>
          <nb-action icon="settings-2-outline"></nb-action>
        </nb-actions>
      </nb-card-body>
    </nb-card>

    <nb-card>
      <nb-card-body>
        <nb-actions size="medium" fullWidth>
          <nb-action>
            <nb-icon class="action-icon" icon="pause-circle-outline"></nb-icon>
            <span>Pause</span>
          </nb-action>
          <nb-action>
            <nb-icon class="action-icon" icon="list-outline"></nb-icon>
            <span>Logs</span>
          </nb-action>
          <nb-action>
            <nb-icon class="action-icon" icon="search-outline"></nb-icon>
            <span>Search</span>
          </nb-action>
          <nb-action>
            <nb-icon class="action-icon" icon="settings-2-outline"></nb-icon>
            <span>Setup</span>
          </nb-action>
        </nb-actions>
      </nb-card-body>
    </nb-card>
  </div>
</div>
