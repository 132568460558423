import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { dummyStrategyOptions, NbAuthService, NbLoginComponent, NB_AUTH_OPTIONS } from '@nebular/auth';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends NbLoginComponent {
  submitted = false;
  data: Date = new Date();
  backendUrl: string = environment.backendUrl;
  loginForm?: FormGroup;
  loading = false;
  returnUrl: string;
  error = '';
  settings: any[];
  settingData: any = {};
  hospital: any;
  userSubscription: Subscription;
  user: any;

  // toastr
  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  status: NbComponentStatus = 'success';
  primaryS: NbComponentStatus = 'primary';

  // 
  // private formBuilder: FormBuilder;
  // private route: ActivatedRoute;
  // protected router: Router;
  // private authenticationService: AuthenticationService;
  
  constructor(
    protected service: NbAuthService,
    protected cd: ChangeDetectorRef,
    @Inject(NB_AUTH_OPTIONS) options = {},
    protected router: Router,
    private authenticationService: AuthenticationService,
    private toastrService: NbToastrService,
    // private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) { 
    super(service, options, cd, router);
  }

  ngOnInit(): void {
    // console.log('user con::', this.authenticationService.isConnected);
    if (this.authenticationService.isConnected) {
      console.log('user is connected!');
      this.showToast(this.primaryS, "Authentification", 'Vous êtes déjà connecté !');
      this.router.navigate(['/']);
    }

    this.authenticationService.userSubject.subscribe(x => {
      this.user = x;
      // console.log('user conn', x );
    });

    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
    
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    console.log('onSubmit');
    this.submitted = true;

    if (this.loginForm.invalid) {
      this.submitted = false;
      this.error = 'Veuillez fournir des informations valide.';
      this.errors = [];
      this.errors.push(this.error);

      return;
    }

    this.errors = [];

    this.loading = true;
    this.authenticationService.login(this.f.email.value, this.f.password.value)
      .pipe(first())
      .subscribe(data => {
        this.loading = false;
        this.submitted = false;
          if(data) {
            this.showToast(this.status, "Authentification", 'Vous êtes connecté !');
            this.router.navigate(['/']);
          } else {
            // this.loading = false;
            this.showToast("warning", "Authentification", 'Une erreur est suvenue. Veuillez contacter l\'administrateur pour plus d\'infos !');
          }
        },
        error => {
          // console.log('Error after logging');
          // console.log(error.message);
          this.errors = [];
          this.error = "Identifiant ou Mot de passe incorrect !";
          this.errors.push(this.error);
          this.loading = false;
          this.submitted = false;
          return;
        }
      );
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    const titleContent = title ? `${title}` : '';

    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
  }
}
