import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NbComponentStatus, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, NB_WINDOW } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication.service';
import { RouterStateSnapshot } from '@angular/router';
import { GeneralService } from '../../../services/general.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';
  loggoutT = 'Deconnexion';
  ResetPassT = 'Modifier mot de passe';

  userMenu = [ 
    { 
      title: this.ResetPassT,
    },
    { 
      title: this.loggoutT,
    },
  ];

  tag = 'user-context-menu';

  status: NbComponentStatus = 'warning';

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private authenticationService: AuthenticationService,
    private generalService: GeneralService,
    @Inject(NB_WINDOW) private window,
  ) {
    
  }

  userLogout() {
    // console.log('Logging out');
    this.authenticationService.logout();
  }

  ngOnInit() {
    this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === this.tag),
        map(({ item: { title } }) => title),
      )
      .subscribe((title) => {
          // console.log(title);
          // this.window.alert(`${title} was clicked!`);
          switch(title) {
            case this.loggoutT:
              this.userLogout();
              break;
            case this.ResetPassT:
              this.generalService.redirectTo('auth/reset-password');
              break;
          }
      });  


    this.currentTheme = this.themeService.currentTheme;

    // this.userService.getUsers()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe((users: any) => this.user = users.nick);
    this.user = this.authenticationService.currentUxerValue;
    // console.log(this.user);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
